@font-face {
  font-family: 'VAG Rundschrift D';
  src: url('./assets/VAGRundschriftD.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

h1 {
  font-family: 'VAG Rundschrift D' !important;
}

body {
  /* /* margin: 0; */
  /* font-family: 'VAG Rundschrift D' !important; */
  /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.carousel__dot--selected {
  background-color: #f53841;
}

.iframe-container {
  position: relative;
  /* overflow: hidden; */
  /* width: 100%; */
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  /* bottom: 0;
  right: 0;
  width: 100%;
  height: 100%; */
}

@media (prefers-reduced-motion: no-preference) {
  .spinner {
    animation: icon-spin infinite 60s linear;
  }
}

@keyframes icon-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.slide-hover {
  transition: all 0.3s ease-in-out;
}

.slide-hover:hover {
  transform: translateY(-15%);
}

.zoom {
  transition: transform 0.2s;
}

.zoom:hover {
  transform: scale(1.025);
}

.hidden {
  opacity: 0;
  filter: blur(5px);
  transform: translateX(-20%);
  transition: all 1s;
  transition-delay: calc(50ms * var(--order));
}

.show {
  opacity: 1;
  filter: blur(0);
  transform: translateX(0);
}

@media(prefers-reduced-motion) {
  .hidden {
    transition: none;
  }
}
